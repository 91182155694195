import axios from "axios";

export const fetchOrderLogs = async ( id_order, headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/order_logs/${id_order}`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchCompanies = async ( headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/companies/all`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchOrderLogsGuest = async ( id_order, headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/order_logs/guest/${id_order}`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const postCompanyDrivers = async ( company_id, driverStatus, price, address, lat, lng, headers ) => {
  try {
    const payload = {
      company_id: company_id,
      status: driverStatus,
      address: address,
      latitude: lat,
      longitude: lng,
    };

    if (price && price > 0) {
      payload.price = parseInt(price);
    }

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/companies/driver`,
      payload,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const checkDriverStatus = async ( driver, headers ) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/checkDriverStatus`,
      { driver_id: driver },
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const getEmployedCars = async ( headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/employedCars`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchOtherOrderOptions = async ( headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/options/${1}`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchOrderById = async ( orderId, headers ) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/company/orders_by_id/`,
      {
        id: orderId,
      },
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchBusinessAccountById = async ( businessId, headers ) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/companies/businessAccountById`,
      {
        business_id: businessId
      },
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchBusinessAccounts = async ( headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/companies/businessAccount`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchBookForClients = async ( businessId, headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/business/bookFor?businessId=${businessId}`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchOnlineDrivers = async ( userId, headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/company/online_drivers/${userId}`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const fetchBusinessClientOrders = async ( businessId, page, headers ) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/business_client/orders?${businessId ? `businessId=${businessId}` : ""}&page=${page}&limit=10&is_logs=${true}`,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

export const updateDispatcherNotes = async ( order_id, notes_to_dispatch, headers ) => {
  try {
    const payload = {
      order_id: order_id,
      notes_to_dispatch: notes_to_dispatch,
    };

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_APP_API_ENDPOINT}/dispatch/order/create_dispatcher_note`,
      payload,
      { headers }
    );

    if (!response.data.error) {
      return response.data
    } else {
      console.log("error ", response.data.error);
      return response.data
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};