import { Button as ChakraButton, Text } from '@chakra-ui/react'
import React from 'react'

const Button = ({ title, onClick, customStyle, fontColor, disabled }) => {
  if (!disabled) {
    disabled = false
  }
  return (
    <ChakraButton
      style={{ ...customStyle, cursor: disabled ? 'not-allowed' : "auto" }}
      isDisabled={disabled}
      className='Button-container'
      onClick={() => onClick()}
    >
      <Text _disabled={{ cursor: disabled ? 'not-allowed' : 'auto' }} disabled={disabled} className='Button-text' style={{ color: fontColor }}>
        {title}
      </Text>
    </ChakraButton>
  )
}

export default Button
