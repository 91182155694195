import { gql} from '@apollo/client';

export const CLIENT_LOGIN_MUTATION = gql`
  mutation ClientLogin($email: String!, $password: String!) {
    clientLogin(email: $email, password: $password) {
      token
      id
      first_name
      last_name
      rating
      share_code
      type
    }
  }
`