import React from "react";
import Select from "react-select";

// Custom styles for react-select
export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: "",
    border: state.hover ? "none" : state.focus ? "none" : "none",
    borderRadius: "5px",
    minHeight: 43,
    backgroundColor: "#f2f2f2",
    fontSize: "18px",
    color: "#333",
    marginTop: "10px",
    boxShadow: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#333",
    fontFamily: "KyrialSans-light",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10, // Ensures dropdown is not cut off by parent containers
  }),
};

const DropDown = ({
  placeholder,
  options = [],
  selectedOption,
  onSelect,
  isValid = true,
  isDisabled = false,
  borderColor = "",
  color = "#333",
  height = 43,
}) => {
  // Custom styles for react-select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: !isValid && !selectedOption ? "red" : borderColor ? borderColor : "",
      border: !isValid && !selectedOption ? "1px" : state.hover ? "none" : state.focus ? "none" : "none",
      borderRadius: "5px",
      height: height ? height : 43,
      backgroundColor: "#f2f2f2",
      fontSize: "18px",
      color: color ? color : "#000000",
      marginTop: "10px",
      boxShadow: !isValid && !selectedOption ? "0 0 0 1px red" : "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      fontFamily: "KyrialSans-light",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10, // Ensures dropdown is not cut off by parent containers
    }),
  };
  
  // Map options to the required format for react-select
  const formattedOptions = options.map((item) => ({
    value: item.id,
    label: item.title
    ? item.title.charAt(0).toUpperCase() + item.title.slice(1)
    : item.name
    ? item.name.charAt(0).toUpperCase() + item.name.slice(1)
    : "",
  }));
  
  // console.log("selectedOption ", formattedOptions, selectedOption);
  return (
    <Select
      placeholder={placeholder}
      options={formattedOptions}
      value={formattedOptions.find((opt) => opt.value == selectedOption)}
      onChange={(selected) => onSelect(selected ? selected.value : null)}
      styles={customStyles}
      isDisabled={isDisabled}
      isSearchable={true}
    />
  );
};

export default DropDown;
